<template>
  <div id=surgeryList class="checkout-items">

    <b-card v-for="surgery in data" :key="surgery.id" class="ecommerce-card" no-body>

      <b-row>

        <b-col cols="12" md="2">
          <div class="item-img">
            <b-img width="200px" height="250px" :src="baseURL + surgery.ImagePath"
              @click="$router.push('/hospitalprofile/' + surgery.ID)" />
          </div>

        </b-col>

        <b-col cols="12" md="4">
          <h2 class="font-bold mt-0 greenColor" style="cursor:pointer; text-decoration: underline;"
            @click="$router.push('/hospitalprofile/' + surgery.ID)">{{ surgery.Name }}</h2>

          <div class="item-rating">
            <ul class="unstyled-list list-inline">
              <span class="item-company" style="white-space: nowrap;">{{ $t('Rate') }}:
                <rate :rateValue="surgery.Rate" />
              </span>
              <br />
              <span class="item-company" style="white-space: nowrap;">{{ $t('RecommendationLevel') }}:
                <rate :rateValue="surgery.RecommendedLevel" />
              </span>
            </ul>
          </div>
        </b-col>

        <b-col cols="12" md="6">
          <div>
            <vs-button v-for="(col, index) in surgery.SurgeryCategorys" :key="index" class=" m-1 " vs-w="2" vs-sm="4"
              @click="$emit('getSurgeries', col.ID, surgery.ID)">
              {{ col.Name }}

            </vs-button>
          </div>
        </b-col>

      </b-row>
      <b-row>
        <div class="item-options px-2">
          <p v-if="surgery.HospitalInfo"
            v-html="surgery.HospitalInfo.substring(0, surgery.numberOfWords == undefined ? 300 : surgery.numberOfWords)">
          </p>

          <u style="color:#00C524" v-if="surgery.numberOfWords == undefined" @click="updateNumberOfWords(surgery)">{{
              $t('ShowMore')
          }}
          </u>
        </div>

      </b-row>

    </b-card>

  </div>


</template>
<script>


import { domain } from "@/gloabelConstant.js";
import hospitalSurgeries from "./HospitalSurgeriesPopup.vue"
import imageLazy from "@/components/image_lazy.vue";
import {
  BBadge, BButton, BCard, BCardBody, BFormSpinbutton, BImg, BLink
  , BCardText, BCol, BFormGroup, BRow, BTable, BCardTitle
} from 'bootstrap-vue';

export default {
  components: {
    hospitalSurgeries,
    imageLazy,
    BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton
    , BCardText, BCol, BFormGroup, BRow, BTable, BCardTitle
  },
  data() {
    return {

      baseURL: domain,

      StatusList: [
        {
          Code: 1,
          Value: "Recommended",
        },
        {
          Code: 2,
          Value: "Highest Price",
        },
        {
          Code: 3,
          Value: "Lowest Price",
        },
        {
          Code: 4,
          Value: "Rate",
        },
      ],
    };
  },
  props: {
    data: {
      type: Array,
      default: () => { },
    },
  },

  methods: {
    GoToTreatmentsProfile(TreatmentID) {
      debugger;
      $router.push({
        name: 'TreatmentsProfile',
        params: { ID: TreatmentID},
      })
    },
    viewBiography() {
      this.showBiography = !this.showBiography;
    },
    updateNumberOfWords(mode) {
      this.$set(mode, "numberOfWords", 200000);
    }
  }
};
</script>
<style>
#surgeryList .vx-row {
  margin: unset;
}

.doctorImage {
  width: 100px;
  height: 100px;
  text-align: end;
}
</style>
