<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="surPop">
    <div class="surPopcustomBGNew">
      <h4 class="text-center mb-5" style="color:white; font-size:xx-large; text-decoration: underline;"> <u class="mr-0"> {{ itemModel.CatName }} </u> </h4>
      <div v-for="(col, index) in itemModel.Surgeries" :key="index">
        <b-row cols="12" md="4">
          <b-col cols="12" md="6">
            <h4 class="ml-1" style="color:white;   padding: 4px 4px 4px;">{{ col.Surgery.Name }}</h4>
          </b-col>
          <b-col cols="12" md="6" class="mb-2">
            <vs-button color="#1DC5F2" style="width:11rem;height:3.2rem;padding:2px;margin-top:-6px" class="" @click="
              $router.push({
                name: 'TreatmentsProfile',
                params: { ID: col.ID },
              })
            ">
              <feather-icon style="color:white;" icon="EyeIcon" svgClasses=" h-4 stroke-current" /> <span class="mr-">{{
                  $t("viewsurgery")
              }} </span>
            </vs-button>
          </b-col>

        </b-row>

      </div>

    </div>
  </div>
</template>

<script>
import Specialty from '../../settings/specialty/Specialty.vue';
import {
  BBadge, BButton, BCard, BCardBody, BFormSpinbutton, BImg, BLink
  , BCardText, BCol, BFormGroup, BRow, BTable, BCardTitle
} from 'bootstrap-vue';

export default {
  data() {
    return {

    };
  },
  props: {
    itemModel: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    Specialty,
    BBadge, BButton, BCard, BCardBody, BFormSpinbutton, BImg, BLink
    , BCardText, BCol, BFormGroup, BRow, BTable, BCardTitle
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  created() {

  },
};
</script>

<style >
#surPop .surPopcustomBG {
  background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
  box-shadow: -3px 0px 22px #00000029;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
.surPopcustomBGNew{
  background: transparent radial-gradient(closest-side at 50% 50%, #2f5f80 0%, #003267 100%) 0% 0% no-repeat padding-box;
  border-radius: 31px 31px 31px 31px;
  opacity: 1;
}
</style>
