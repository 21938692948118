<template>

    <div class="checkout-items list-view product-checkout mt-0">
        <b-card :style="selectedsurgeryID == surgery.ID ? { backgroundColor: '#D2FFFE' } : {}" v-for="surgery in data"
            :key="surgery.id" class="ecommerce-card" no-body>
            <b-row class="sticky list-view product-checkout mt-0">
                <b-col class="item-name mt-0 py-1" cols="12" md="2">
                    <div class="item-img">
                        <b-link class="m-2">
                            <b-img class="round" width="170px" height="150px" v-if="surgery.ImagePath"
                                :src="baseURL + surgery.ImagePath" :alt="`${surgery.Name}-${surgery.ID}`" />
                            <b-img class="round" width="70%" height="50%" v-else alt=""
                                src="@/assets/images/DoctorPlaceHolder.png" />
                        </b-link>
                    </div>
                </b-col>

                <b-col class="item-name" cols="12" md="4">

                    <b-card-body>
                        <b-row>
                            <b-col >
                                <h6 class="mb-1">

                                    <b-link class="text-body" @click="$router.push('/hospitalprofile/' + surgery.ID)"
                                        v-if="surgery.Name"> {{ surgery.Name }}
                                    </b-link>

                                </h6>
                                <div class="item-options">
                                    <p v-if="surgery.HospitalInfo"
                                        v-html="surgery.HospitalInfo.substring(0, surgery.numberOfWords == undefined ? 150 : surgery.numberOfWords)">
                                    </p>

                                    <u style="color:#00C524" v-if="surgery.numberOfWords == undefined"
                                        @click="updateNumberOfWords(surgery)">{{
        $t('ShowMore')
}}
                                    </u>
                                </div>


                            </b-col>

                        </b-row>
                    </b-card-body>

                </b-col>
                <b-col class="item-name item-options mt-2 border-right border-3" cols="12" md="3">
                    <div  class="item-rating">
                        <span class="item-company">{{ $t('Rate') }}: </span>
                        <rate :starsize="15" :rateValue="surgery.Rate" />
                        <div class="mt-1"></div>
                        <span class="item-company">{{ $t('RecommendationLevel') }}: </span>
                        <rate  :starsize="15" :rateValue="surgery.RecommendedLevel" />
                    </div>
                </b-col>
                <!-- <div class=" border border-1" style=""></div>  -->
                <b-col class="mt-5" cols="12" md="3">
                    <div class=" center">
                        <div class="item-wrapper">
                            <div class="item-cost border border-dark rounded mx-1">
                                <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" label="Name"
                                    @input="ViewSerguryList(surgery); selectedsurgeryID = surgery.ID"
                                    v-model="surgery.SurgeryCategorys.ID" :options="surgery.SurgeryCategorys"
                                    id="destinationMobile" :placeholder="$t('Surgeries')" :reduce="(ID) => ID.ID" />
                            </div>
                        </div>
                    </div>

                </b-col>

            </b-row>

        </b-card>
    </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import { BBadge, BButton, BCard, BCardBody, BCol, BFormSpinbutton, BImg, BLink, BRow } from 'bootstrap-vue';
export default {
    components: {
        BCard, BCardBody, BLink, BImg, BButton, BBadge, BFormSpinbutton, BRow, BCol
    },
    data() {
        return {
            selectedsurgeryID: null,
            showDismissibleAlert: false,
            EnglishLan: false,
            ShowConfirmation: false,
            Model: {},
            medicalPackage: {},
            ShowLoginPopup: false,
            value1: "",
            baseURL: domain,
            ItemModel: {
                Surgeries: [],
            },
            StatusList: [
                {
                    Code: 1,
                    Value: "Recommended",
                },
                {
                    Code: 2,
                    Value: "Highest Price",
                },
                {
                    Code: 3,
                    Value: "Lowest Price",
                },
                {
                    Code: 4,
                    Value: "Rate",
                },
            ],
        };
    },
    methods: {
        ViewSerguryList(surgery) {
            debugger;
            var ID = surgery.SurgeryCategorys.ID;
            var HospitalID = surgery.HospitalSurgerys[0].HospitalID;

            var obj = {};
            var vm = this;
            obj.SurgeyCategoryID = ID;
            obj.HospitalID = HospitalID;
            this.$store
                .dispatch("SurgeryList/GetAlHospitalSurgeyByCategory", obj)
                .then((res) => {
                    if (res.data == undefined)
                        this.ItemModel.Surgeries = res[0].data.Data;
                    else
                        this.ItemModel.Surgeries = res.data.Data;
                    debugger;
                    //this.ItemModel.CatName = this.ItemModel.Surgeries[0].Surgery.SurgeryCategory.Name;
                    this.$emit('ViewHospitalSurgeriesList', vm.ItemModel, surgery)

                });

        },

        getSurgeries(ID, HospitalID) {
            debugger;
            var obj = {};
            obj.SurgeyCategoryID = ID;
            obj.HospitalID = HospitalID;
            this.$store
                .dispatch("SurgeryList/GetAlHospitalSurgeyByCategory", obj)
                .then((res) => {
                    if (res.data == undefined)
                        this.ItemModel.Surgeries = res[0].data.Data;
                    else
                        this.ItemModel.Surgeries = res.data.Data;

                    this.ItemModel.CatName = this.ItemModel.Surgeries[0].Surgery.SurgeryCategory.Name;
                    this.showPopub = true;
                });
        },
        GoToTreatmentsProfile(TreatmentID) {
            debugger;
            $router.push({
                name: 'TreatmentsProfile',
                params: { ID: TreatmentID },
            })
        },
        viewBiography() {
            this.showBiography = !this.showBiography;
        },
        updateNumberOfWords(mode) {
            this.$set(mode, "numberOfWords", 200000);
        }
    },
    props: {
        data: {
            type: Array,
            default: () => { },
        },

    },

}
</script>

<style>
#destinationMobile .vs__dropdown-toggle {
    border-radius: 18px;
}

.mycontent-left {
    border-right: 1px dashed #333;
}
</style>
