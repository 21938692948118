<template>

  <b-col class="checkout-options sticky">

    <b-card>
      <h6 class="mb-0 item-name" style="color:#8379F2;font-weight: bold;">
      </h6>
      <label class="section-label mb-1">Options</label>

      <div class="price-details">
      <span v-if="!HospitalName">{{ $t('SelectHospitalSurgery')}}</span>
         <span v-if="HospitalName">{{HospitalName }}</span>  
        <hr>
        <!-- <div v-if="!itemModel.selectedsurgeryID">
      <span>please select surgery!</span>
    </div> -->
        <div v-for="(col, index) in itemModel.Surgeries" :key="index">
              <h5 class="ml-0 cursor-pointer input" style="color:#8379F2; padding: 4px 4px 4px;" 
              @click="
                $router.push({
                  name: 'TreatmentsProfile',
                  params: { ID: col.ID },
                })
              ">{{ col.Surgery.Name }}</h5>
              <!-- <vs-button color="#1DC5F2" style="width:11rem;height:3.2rem;padding:2px;margin-top:-6px" class="" @click="
                $router.push({
                  name: 'TreatmentsProfile',
                  params: { ID: col.ID },
                })
              ">
                <feather-icon style="color:white;" icon="EyeIcon" svgClasses="h-4 stroke-current" /> <span
                  class="mr-">{{
            $t("viewsurgery")
                  }} </span>
              </vs-button> -->
        </div>

      </div>

    </b-card>
  </b-col>

</template>

<script>
import Specialty from '../../settings/specialty/Specialty.vue';
import {
  BBadge, BButton, BCard, BCardBody, BFormSpinbutton, BImg, BLink
  , BCardText, BCol, BFormGroup, BRow, BTable, BCardTitle
} from 'bootstrap-vue';


export default {
  data() {
    return {

    };
  },
  props: {
    itemModel: {
      type: Object,
      default: () => { },
    },
    HospitalName: {
      type: Object,
      default: () => { },
    },

  },
  components: {
    Specialty,
    BBadge, BButton, BCard, BCardBody, BFormSpinbutton, BImg, BLink
    , BCardText, BCol, BFormGroup, BRow, BTable, BCardTitle
  },
  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    surgeries() {
      return this.$store.state.SurgeryList.surgeries;
    },
  },
  created() {

  },
  methods: {
    GoToTreatmentsProfile(TreatmentID) {
      debugger;
      $router.push({
        name: 'TreatmentsProfile',
        params: { ID: TreatmentID },
      })
    },
  },
};
</script>
<style scoped="scss">
div.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 25%;
  font-size: 25px;
}
.pointer {
  cursor: pointer;
}
input{
  caret-color: red;
}
</style>