<template>
  <div class="list-view product-checkout mt-0">

    <div id="SAT" class="mb-15">
      <div id="searchDev" :class="windowWidth >= 1200 ? 'searchPosition' : ''">

        <div class="search w-full mt-10">
          <feather-icon icon="ChevronDownIcon" @click="content_visible = !content_visible"
            class="cursor-pointer m-1"></feather-icon>

          <b-card v-if="content_visible">
            <b-row class="w-full ">
              <b-col cols="12" md="3">
                <b-row>
                  <feather-icon icon="CommandIcon" class="cursor-pointer " style="margin:2%"></feather-icon>
                  <v-select class="rounded border-dark border-box" :dir="$vs.rtl ? 'rtl' : 'ltr'" style="width: 80%"
                    label="Name" @input="UpdateSelectedSurgCategoryColor(search.SurgeryCategoryID)"
                    v-model="search.SurgeryCategoryID" :options="surgeryCategories" :placeholder="$t('Specialty')"
                    :reduce="(ID) => ID.ID" />
                </b-row>
              </b-col>

              <b-col cols="12" md="2">
                <b-row> <feather-icon icon="GitBranchIcon" class="cursor-pointer " style="margin:2%"></feather-icon>
                  <v-select class=" rounded border-dark" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="SimpleSearchHospitals"
                    style="width: 80%" id="destinationMobile" label="Name" v-model="search.SurgeryID"
                    :options="surgeries" :reduce="(ID) => ID.ID" :placeholder="$t('Surgeries')" /></b-row>

              </b-col>

              <b-col cols="12" md="2">
                <b-row>
                  <feather-icon icon="MapPinIcon" class="cursor-pointer " style="margin:2%"></feather-icon>
                  <v-select class="rounded border-dark" :dir="$vs.rtl ? 'rtl' : 'ltr'" style="width: 80%" label="Name"
                    v-model="search.CountryID" :options="countries" @input="SimpleSearchHospitals"
                    :reduce="(ID) => ID.ID" :placeholder="$t('Destination')" />
                </b-row>

              </b-col>

              <b-col cols="12" md="3">
                <b-row>
                  <feather-icon icon="UsersIcon" class="cursor-pointer " style="margin:2%"></feather-icon>
                  <v-select class="rounded border-dark" :dir="$vs.rtl ? 'rtl' : 'ltr'" style="width: 80%" label="Name"
                    @input="UpdateData()" v-model="search.HospitalID" :options="hospitalsList" :reduce="(ID) => ID.ID"
                    :placeholder="$t('hospitals')" />
                </b-row>

              </b-col>

              <b-col cols="12" md="1">
                <div class=" ">
                  <b-row>
                    <b-col cols="12" md="4">
                      <b-button @click="startSearchSurAndTreat" variant="primary">
                        {{ $t("Search") }}
                      </b-button>
                    </b-col>
                    <b-col cols="12" md="5">
                      <feather-icon style="padding-top: 10px" class="whitecolor ml-5 mr-5" icon="SearchIcon" />
                    </b-col>
                  </b-row>
                </div>
              </b-col>

            </b-row>

          </b-card>
        </div>
      </div>

      <form-wizard ref="refFormWizard" color="#2753D9" :title="null" :subtitle="null" finish-button-text="Submit"
        back-button-text="Previous" hide-buttons class="checkout-form-wizard steps-transparent">

      <b-row>
        <b-col class="12" md="9">
          <div style="width: 100%">
            <SurgeryAndTreatmentCart @ViewHospitalSurgeriesList="ViewHospitalSurgeriesList" :data="hospitals"
              v-if="hospitals.length > 0" @getSurgeries="getSurgeries" />
            <h1 style="text-align:center" v-if="IsShowMore && hospitals && hospitals.length > 0" @click="getNextHospitals">
              {{ $t('ShowMore') }}</h1>

          </div>
        </b-col>
        <b-col cols="12" md="3" class="px-0 mt-2">
          <ViewHospitalSurgeries :itemModel="ItemModel" :HospitalName="HospitalName" />
        </b-col>
      </b-row>
    </form-wizard>

    </div>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleSurgery from "@/store/settings/surgery/moduleSurgery.js";
import moduleSurgeryCategory from "@/store/settings/surgeryCategory/moduleSurgeryCategory.js";
import ViewHospitalSurgeries from '@/views/Patient/Surgery/ViewHospitalSurgeries.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import { BButton, BCard, BCardText, BCardTitle, BCol, BFormGroup, BImg, BRow, BTable } from 'bootstrap-vue';
import CustomPaging from "../../../components/CustomPaging.vue";
import HospitalSurgeries from "./HospitalSurgeriesPopup.vue";
import SurgeryAndTreatmentCart from "./SurgeryAndTreatmentCart.vue";
import SurgeryAndTreatmentsList from "./SurgeryAndTreatmentsList.vue";
import { FormWizard, TabContent } from 'vue-form-wizard'


export default {
  components:
  {
    SurgeryAndTreatmentsList,
    CustomPaging,
    HospitalSurgeries,
    BCardActions,
    ViewHospitalSurgeries,
    BRow,
    BCol,
    BTable,
    BCardText,
    BButton,
    BCard,
    BFormGroup,
    BImg,
    BCardTitle,
    SurgeryAndTreatmentCart,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      showPopub: false,
      EnglishLan: false,
      IsShowMore: true,
      showAddRequest: false,
      content_visible: true,

      ItemModel: {
        Surgeries: [],
      },

      search: {
        SurgeryCategoryID: "",
        SurgeryID: "",
        CountryID: "",
        HospitalID: "",
        filterID: "",
        PageSize: 10,
        PageNumber: 1,
        SurgeryActive: true
      },
      HospitalName: "",

      ActiveIndex: null,
      CountryActiveIndex: null,
      SurgActiveIndex: null,
      baseURL: domain,
      CurrentPage: 1,
      CountPerPage: 4,
    };
  },
  methods: {
    ViewHospitalSurgeriesList(ItemModel, surgery) {
      debugger;
      this.ItemModel = ItemModel;
      this.HospitalName = surgery.Name;
    },
    UpdateData() {
      this.UpdateSpecialities();
      this.UpdateSurgeries();
      this.GetAllAppointmentSurgeryCountries()
    },
    UpdateSpecialities() {
      this.$store.dispatch("SurgeryCategoryList/SearchSurgeryCategories", this.search);
    },
    UpdateSurgeries() {
      this.$store.dispatch("SurgeryList/SearchSurgeries", this.search);
    },
    startSearchSurAndTreat() {
      this.search.PageNumber = 1;
      this.search.PageSize = 10;
      this.IsShowMore = true;
      this.$store.commit("SurgeryList/SET_SearchHospitals", []);

      this.searchSurAndTreat();
    },
    getNextHospitals() {
      this.search.PageNumber++;
      this.searchSurAndTreat();
    },
    getSurgeries(ID, HospitalID) {
      debugger;
      var obj = {};
      obj.SurgeyCategoryID = ID;
      obj.HospitalID = HospitalID;
      this.$store
        .dispatch("SurgeryList/GetAlHospitalSurgeyByCategory", obj)
        .then((res) => {
          if (res.data == undefined)
            this.ItemModel.Surgeries = res[0].data.Data;
          else
            this.ItemModel.Surgeries = res.data.Data;

          this.ItemModel.CatName = this.ItemModel.Surgeries[0].Surgery.SurgeryCategory.Name;
          this.showPopub = true;
        });
    },
    clearSearch() {
      this.search.SurgeryCategoryID = "";
      this.search.SurgeryID = "";
      this.search.CountryID = "";
      this.search.HospitalID = "";
      this.search.filterID = "";
      this.search.PageSize = 10;
      this.search.PageNumber = 1;

      this.UpdateData();
    },
    GoBack() {
      this.CurrentPage = this.CurrentPage - 1;
    },

    back() {
      if (this.hospitals.length == 0) this.$router.go(-1);
      else this.$store.commit("SurgeryList/SET_SearchHospitals", []);
    },
    searchSurAndTreat() {
      debugger
      this.$vs.loading();
      this.$store
        .dispatch("SurgeryList/SearchHospitalsPaging", this.search)
        .then((res) => {
          debugger
          this.$vs.loading.close();
          if (this.hospitals == null || this.hospitals.length == 0) {
            this.$vs.notify({
              title: this.$t("NoData"),
              text: this.$t("NoDataToshow"),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning",
            });
          }
          if (res.data.Data.length == 0) {
            this.IsShowMore = false;
          }

        });
    },
    filterSurgeries() {
      this.$vs.loading();

      this.search.PageNumber = 1;
      this.$store.commit("SurgeryList/SET_SearchHospitals", []);

      this.$store
        .dispatch("SurgeryList/SearchHospitalsPaging", this.search)
        .then((re) => {
          this.$vs.loading.close();
        });
    },
    UpdateSelectedSurgCategoryColor(ID) {
      this.search.SurgeryID = null;

      this.SimpleSearchHospitals();
      this.GetAllAppointmentSurgeryCountries()

    },

    SearchHospitals(ID) {
      debugger;
      this.CountryActiveIndex = this.countries.findIndex((i) => i.ID === ID);
      var Obj = {
        countryID: ID,
      };

      this.$store.dispatch("CountryList/SearchHospitals", Obj).then(() => {

      });
    },
    selectDestination(destination) {
      this.search.CountryID = destination.ID;
    },

    selectCountry(destination, index) {
      if (destination) {
        this.search.CountryID = destination.ID;
        this.CountryActiveIndex = index;
      } else {
        this.CountryActiveIndex = null;
        this.search.CountryID = "";
      }
      debugger;
    },
    selectSurgery(destination, index) {
      if (destination) {
        this.search.SurgeryID = destination.ID;
        this.SurgActiveIndex = index;
      } else {
        this.SurgActiveIndex = null;
        this.search.SurgeryID = "";
      }
    },
    SimpleSearchHospitals() {
      this.$store.dispatch("HospitalList/SimpleSearchHospitals", this.search)
      this.UpdateData()
    },
    GetAllAppointmentSurgeryCountries() {
      this.$store.dispatch("CountryList/GetAllAppointmentSurgeryCountries", this.search);
    }
  },
  computed: {
    topDestinationsPage() {
      return this.$store.state.CountryList.topDestinations.slice(
        (this.CurrentPage - 1) * this.CountPerPage,
        (this.CurrentPage - 1) * this.CountPerPage + this.CountPerPage
      );
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    hospitals() {
      debugger;
      return this.$store.state.SurgeryList.hospitals;
    },
    doctors() {
      return this.$store.state.patientList.doctors;
    },
    surgeryCategories() {
      return this.$store.state.SurgeryCategoryList.surgeryCategories;
    },
    surgeriesByCat() {
      return this.$store.state.SurgeryList.surgeriesByCat;
    },
    surgeries() {
      return this.$store.state.SurgeryList.surgeries;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    hospitalsList() {
      return this.$store.state.HospitalList.searchSimpleHospitals;
    },
    doctorCountries() {
      return this.$store.state.CountryList.doctorCountries;
    },
    topDestinations() {
      return this.$store.state.CountryList.topDestinations;
    },
  },
  created() {
    debugger
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!moduleSurgeryCategory.isRegistered) {
      this.$store.registerModule("SurgeryCategoryList", moduleSurgeryCategory);
      moduleSurgeryCategory.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }
    if (!moduleSurgery.isRegistered) {
      this.$store.registerModule("SurgeryList", moduleSurgery);
      moduleSurgery.isRegistered = true;
    }
    debugger
    this.$store.dispatch("SurgeryList/GetSurgerysByCategory", { 'SurgeryCategoryID': null });

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    this.$store.commit("SurgeryList/SET_SearchHospitals", []);

    const CountryID = this.$route.params.CountryID;
    const SpecialtyID = this.$route.params.SpecialtyID;
    if (CountryID != undefined || SpecialtyID != undefined) {
      if (SpecialtyID != undefined) {
        this.search.SpecialtyID = parseInt(SpecialtyID);
      }

      if (CountryID != undefined) {
        this.search.CountryID = parseInt(CountryID);
      }
      this.$store.state.SurgeryList.search = this.search;
    }
    else {
      this.search = this.$store.state.SurgeryList.search;
    }
    // this.SearchHospitals(CountryID);
    this.SimpleSearchHospitals();

    this.searchSurAndTreat();
    this.UpdateSpecialities()

    this.GetAllAppointmentSurgeryCountries()

  },
  mounted() {
   
  },
};
</script>
<style  >
.searchPosition {
  position: absolute;
  top: 278px;
  z-index: 107;
  width: 73%;
}

#SAT .SliderStyleMobile {
  width: 100%;
  z-index: 1;
}

#SAT .SliderStyle {
  position: absolute;
  top: 0px;
  z-index: 90;
  left: 89px;
  width: 90vw;
  height: 49vh;
  object-fit: cover;
}
</style>
<style>
.sr-only[data-v-fde73a0c] {
  left: 0px !important;
}

.sr-only {
  left: 0px !important;
}

#SurgerySearch .vs-list--slot {
  width: 100%;
}

#SurgerySearch .vs-list {
  padding: 0px;
}

#SurgerySearch .vs-card--content {
  padding: 0px;
}

#SurgerySearch .listScroll {
  height: 170px;
  overflow-y: scroll;
}

#SurgerySearch .ListHeader {
  height: 46px;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
}

#SurgerySearch .listItem {
  height: 40px;
  cursor: pointer;
  padding: 10px;
}

::-webkit-scrollbar {
  height: 5px;
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#searchdiv {
  margin: -56px 31px;
}

.vs__dropdown-toggle {
  background: white;
  border: 2px solid #454a62;
  border-radius: 9px;
}
</style>

<style>
.imageDiv {
  display: block;
}

#SurgerySearch .shadowDiv {
  position: relative;
  height: 115px;
  bottom: 120px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
}
</style>
<style>
#specialyMobile .vs__dropdown-toggle {
  border-radius: unset;
}

#destinationMobile .vs__dropdown-toggle {
  border-radius: 18px;
}
</style>

<style scoped>
.socialImage {
  width: 50px;
  height: 50px;
}

.searchCard {
  height: flex;
  border-radius: 18px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000003d;

  border-radius: 24px;
  opacity: 1;
}

.searchCardMobil {
  height: flex;

  border-radius: 18px;
}

.hospitalListMobil {
  padding-top: 20px;
}

.searchButton {
  height: 80px;
  border-bottom-right-radius: 18px;
  border-top-right-radius: 18px;
}

.searchButtonMobil {
  height: 50px;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
}

.Hdivider {
  background: #00000029;
  width: 100%;
  height: 2px;
  margin-top: 5px;
}

.divider {
  background: #00000029;
  width: 3px;
  height: 80px;
}
</style>

<style lang="scss">
#SAT .vs-inputx {
  /* background-color: $primary; */
}

#SAT .input-span-placeholder {
  color: white;
}
</style>
<style>
.vue-flux {
  height: 80vh;
}

#SAT .vs__dropdown-toggle {
  border: 0px solid #454a62 !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

#SAT .v-select .vs__dropdown-toggle .vs__search {
  font-size: 15px;
}

#SAT .vs__selected {
  font-size: 1rem;
  white-space: nowrap;
  font-weight: bold !important;
  padding-top: 0.1rem;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>

