<template>
    <div class="">
      <vs-row vs-align="center" vs-type="flex" vs-justify="center" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="mt-4"
        >
        <vs-button
            type="flat"
            class="text-black"
            icon-pack="feather"
            icon="icon-chevrons-left"
            :disabled="this.CurrentPage == 1"
            @click="$emit('GoToFirstPage')"
            v-if="ViewLastNavigation"
          ></vs-button>
          <vs-button
            type="flat"
            class="text-black"
            icon-pack="feather"
            :disabled="this.CurrentPage == 1"
            icon="icon-chevron-left"
            @click="$emit('GoBack')"
          ></vs-button>
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="mt-4"
        >
           {{ CurrPage }} of {{ Math.ceil(ModelLength / CountPerPage) }}
        </vs-col>
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="2"
          class="mt-4"
        >
          <vs-button
            type="flat"
            class="text-black"
            icon-pack="feather"
            :disabled="
              this.CurrentPage == Math.ceil(ModelLength / CountPerPage)
            "
            icon="icon-chevron-right"
            @click="$emit('GoNext')"
          ></vs-button>

             <vs-button
            type="flat"
            class="text-black"
            icon-pack="feather"
            icon="icon-chevrons-right"
            :disabled="
              this.CurrentPage == Math.ceil(ModelLength / CountPerPage)
            "
            @click="$emit('GoToLastPage')"
             v-if="ViewLastNavigation"
          ></vs-button>
        </vs-col>
      </vs-row>
    </div>
</template>
<script>
export default {
    props:{
        CountPerPage:{
             type: Number,
             required: true
         },
         ModelLength:{
              type: Number,
             required: true
         },
      CurrentPage:{
            type: Number,
             required: true
         },
          ViewLastNavigation:{
            type: Boolean,
             required: false
         },
    },
    data(){
        return {
         
        }
    },
    computed:{
        CurrPage() {  
         return this.CurrentPage;
       },
    }
}
</script>